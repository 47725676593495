import axios from "axios";
const ENDPOINT = "/api/v1/school-classes";

const get = async payload => {
  return axios.get(ENDPOINT, { params: payload });
};

const getClasses = async payload => {
  return axios.get(ENDPOINT + '/sector-classes', { params: payload });
};

const streams = async payload => {
  return axios.get(ENDPOINT + '/streams', { params: payload });
};

const create = async payload => {
  return await axios.post(ENDPOINT, payload);
};

const update = async payload => {
  return await axios.put(ENDPOINT, payload);
};

const remove = async payload => {
  return axios.delete(ENDPOINT + "/" + payload);
};

export { get, getClasses, streams, create, update, remove };
