<template>
  <div class="metadata-options">
    <!-- <pre>{{ schoolClass }}</pre> -->

    <v-card-actions class="pa-0">
      <page-title :title="title" />
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-card>
      <v-simple-table class="table results block-center tableReg">
        <template v-slot:default>
          <thead v-if="headers2 && headers2.length > 0">
            <tr>
              <template v-for="(header, index) in headers">
                <th
                  :class="`${header.class}`"
                  v-if="header.type === 'horizontal'"
                  :key="index"
                  rowspan="2"
                  style="height:220px"
                >
                  {{ header.text }}
                </th>

                <th
                  v-if="header.type === 'vertical'"
                  :key="index"
                  :class="`${header.class}`"
                  rowspan="2"
                >
                  <span class="vertical">{{ header.text }}</span>
                </th>
              </template>
            </tr>
          </thead>
          <tbody v-if="headers2.length > 0">
            <tr v-for="(item, i) in students" :key="i">
              <td>{{ item.index }}</td>
              <td>{{ item.student_necta_serial }}</td>
              <td>{{ item.display_name }}</td>
              <td>{{ item.gender.descriptions }}</td>
              <!-- <td>{{ item.schoolClass.name }}</td> -->
              <td>{{ item.schoolClassStream.name }}</td>
              <!-- <td>{{ item.academicYear.name }}</td> -->

              <template v-for="(header, idx) in headers2">
                <td
                  v-if="isRegisteredFor(item.subjects, header)"
                  class="text-left"
                  :key="idx"
                >
                  <!-- {{ header.code }} -->
                  <v-icon color="primary">mdi-check-bold</v-icon>
                </td>
                <td v-else class="text-left" :key="idx">-</td>
              </template>
            </tr>
          </tbody>
        </template>

        <template v-slot:top>
          <v-card-title>
            <v-col cols="2" sm="12" md="2" class="pa-5">
              <v-select
                label="Select Academic Year"
                :items="years"
                :item-text="'name'"
                return-object
                required
                clearable
                hide-details
                v-model="year"
              ></v-select>
            </v-col>
            <v-col cols="2" sm="12" md="2" class="pa-0">
              <v-select
                v-model="schoolClass"
                label="Select class"
                :items="schoolClassesLists"
                :item-text="'schoolClass.name'"
                @change="
                  loadStudentsBySubjects($event);
                  fetchSchoolSubjects();
                  loadStreams($event);
                "
                @click:clear="loadStudentsBySubjects()"
                return-object
                required
                clearable
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="2" sm="12" md="2" class="pa-5">
              <v-select
                label="Filter by Stream"
                :items="streams"
                :item-text="'name'"
                @change="loadStudentsByStreams($event)"
                @click:clear="loadStudentsByStreams()"
                return-object
                required
                clearable
                hide-details
              ></v-select>
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchOptions"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Students'"
                :append-icon="''"
                v-if="items.length > 0"
              />
            </v-col>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionsButtons
            :update-function="openDialog"
            :delete-function="openDeleteDialog"
            :formData="item"
            :updateIcon="' mdi-pencil-box-outline'"
            :deleteIcon="'mdi-trash-can-outline'"
            :updateButtonColor="''"
            :deleteButtonColor="''"
          />
        </template>
        <!-- </v-data-table> -->
      </v-simple-table>
    </v-card>
    <div class="pt-5 mt-5" v-if="headers2.length > 0">
      <v-card outlined flat width="25%" elevation="9">
        <v-data-table
          :items="headers2"
          hide-default-footer
          dense
          class="statistics-table "
        >
          <template v-slot:body>
            <th class="primary--text">No.</th>
            <th class="primary--text">Subject Name</th>
            <th class="primary--text">Number Students</th>
            <tbody>
              <tr v-for="(header, idx) in headers2" :key="header.text">
                <td>
                  {{ idx + 1 }}
                </td>
                <td>
                  {{ header.text }}
                </td>
                <td class="text-center">
                  {{ header.subjectCount }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { getSchoolSubjects, fetchStudentSubjects } from "./services";
import { get as getClasses, streams } from "../school-classes/services";

import { get as schoolYears } from "../academic-years/services";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {},
  name: "SchoolSubjects",
  data() {
    return {
      approvingTitle: null,
      isOpenApproval: false,
      selected: [],
      schoolClassesLists: [],
      studentSubjects: [],
      schoolSubjects: [],
      schoolClass: {},
      students: [],
      schoolClassStream: null,
      year: null,
      years: [],
      title: "Manage Student-Subjects",
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      params: {},
      item: null,
      schoolClassStream: "",
      items: [],
      levels: [],
      streams: [],
      subjects: [],
      isApproved: false,
      student: null,
      headers: [],
      headers2: [],
      dentsSubjects: [],
      headers1: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
          type: "horizontal",
        },
        {
          text: "IndexNumber",
          align: "start",
          sortable: false,
          value: "student.display_name",
          class: "primary--text",
          type: "horizontal",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "student.display_name",
          class: "primary--text",
          type: "horizontal",
        },

        {
          text: "Gender",
          align: "start",
          sortable: false,
          value: "student.gender.name",
          class: "primary--text",
          type: "horizontal",
        },

        {
          text: "Stream",
          align: "start",
          sortable: false,
          value: "school_class_stream.name",
          class: "primary--text",
          type: "horizontal",
        },
      ],
    };
  },

  computed: {
    // dataIs() {
    //   return this.schoolSubjects.;
    // },

    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        // this.init(this.params);
      },
    },
  },
  methods: {
    isRegisteredFor(subjects, item) {
      return subjects.filter((s) => s.code === item.code).length > 0
        ? true
        : false;
    },
    searchOption() {},

    fetchSchoolSubjects() {
      let params = {
        schoolClassId: this.schoolClass.id,
        yearId: this.year.id,
        schoolClassStreamId: this.schoolClassStream
          ? this.schoolClassStream.id
          : null,
      };

      getSchoolSubjects(params)
        .then((response) => {
          this.headers2 = response.data.map((item) => ({
            ...item,

            text: "0" + item.code + "-" + item.name,
            align: "start",
            sortable: false,
            value: "student.display_name",
            class: "primary--text",
            type: "vertical",
          }));

          this.headers = [...this.headers1, ...this.headers2];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    loadAcademicYears() {
      schoolYears()
        .then((response) => {
          this.years = response.data[0].data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    exit() {
      return;
    },

    getSchoolClasses() {
      getClasses().then((response) => {
        this.schoolClassesLists = response.data[0]
          ? response.data[0].data
              .map((item) => ({
                ...item,
                schoolClass: item.school_class,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudentsBySubjects(data = null) {
      this.params.schoolClassId = data && data.id ? data.id : null;

      fetchStudentSubjects(this.params).then((response) => {
        this.students = response.data.data
          .map((item) => ({
            ...item,
            schoolClassStream: item.school_class_stream,
            schoolClass: item.school_class,
            first_name: item.students.first_name,
            middle_name: item.students.middle_name,
            surname: item.students.surname,
            display_name: item.students.display_name.toUpperCase(),
            gender: item.students.gender,
            birth_date: item.students.birth_date,
            student: item.students,
            academicYear: item.academic_year,
            subjects: item.students.subjects,
          }))
          // .sort(function(a, b) {
          //   if (a > b) return 1;
          //   return -1;
          // })
          .map((item, index) => ({
            ...item,
            index: ++index,
          }));
      });

      if (data === null) {
        this.schoolClass = {};
      }
    },

    loadStreams(data) {
      this.params.schoolClassId = data && data.id ? data.id : null;
      //this.loadStudentsByStreams(this.params);
      streams(this.params).then((response) => {
        //console.log("KKK", response.data[0]);
        this.streams = response.data[0]
          ? response.data[0]
              .map((item) => ({
                ...item,
              }))
              .sort(function(a, b) {
                return a.name > b.name ? 1 : -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudentsByStreams(data = null) {
      this.params.schoolClassStreamId = data && data.id ? data.id : null;
      this.loadStudentsBySubjects(this.params);
    },

    searchOptions(optionName) {
      (this.params.search = optionName ? optionName : null),
        this.init({
          size: this.params.size,
          total: this.params.total,
          ...this.params,
        });
    },
  },
  created() {},
  watch: {
    pagination: {
      handler() {
        // this.loading = true;
        // this.init(this.params);
      },
      update() {
        // this.loading = true;
        //this.init(this.params);
      },
      deep: true,
    },
  },

  mounted() {
    this.getSchoolClasses();

    this.loadAcademicYears();
  },
};
</script>

<style scoped>
div.vertical {
  margin-left: -85px;

  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safarstatistics-table i/Chrome */
  -moz-transform: rotate(-90deg); /* Firefox */
  -o-transform: rotate(-90deg); /* Opera */
  -ms-transform: rotate(-90deg); /* IE 9 */
  overflow-wrap: break-word;
}

th.vertical {
  height: 220px !important;
  line-height: 14px;
  padding-bottom: 20px;
  text-align: left;
  overflow-wrap: break-word;
}

/*ROTATED TABLE*/
/* .tableReg.rotate-table-grid {
  box-sizing: border-box;
  border-collapse: collapse;
} */
.tableReg tr,
.tableReg td,
.tableReg th {
  position: relative;

  table-layout: fixed;
}
.tableReg thead,
th,
td,
tr {
  border-left: thin solid #dddddd;
}

.tableReg thead,
th {
  border-top: thin solid #dddddd;
}

.tableReg th span {
  transform-origin: 0 50%;
  transform: rotate(-90deg);
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 350%;
  word-wrap: break-word;
  overflow: hidden;
}

.statistics-table,
th,
td,
tr {
  border-left: thin solid #dddddd;
}
.statistics-table th,
tr {
  border-top: thin solid #dddddd;
}
</style>
